import React, { useEffect, useRef, useState } from "react";
import {
  CartIcon,
  CrossIcon,
  HamBurgerMenu,
  HeartIcon,
  SearchIcon,
} from "../utils/Icons";
import { Link } from "react-router-dom";
import BottomSection from "./BottomSection";
import GoogleLoginButton from "./GoogleLogin";
import { getCartItems } from "../utils/cartUtils";
import Logo from './Logo'

const Header = () => {
  const [showForm, setShowForm] = useState(false);

  // to get the number of items in the cart
  const [cartItemCount, setCartItemCount] = useState(0);
  useEffect(() => {
    const updateCartCount = () => {
      const cart = getCartItems();
      setCartItemCount(cart.length);
    };

    // Initialize count
    updateCartCount();
    // Listen for cart updates
    window.addEventListener("cartUpdated", updateCartCount);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("cartUpdated", updateCartCount);
    };
  }, []);

  // const handleGoogleLogin = () => {
  //   window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
  // };

  // const handleLogout = () => {
  //   localStorage.removeItem("authToken");
  //   window.location.href = "/login";
  // };

  return (
    <>
      <div className="max-w-7xl mx-auto py-5 h-20 px-2">
        <div className="flex items-center justify-between">
          <div className="font-Qochy font-normal text-2xl">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="flex items-center justify-center gap-3">
            <ul className="flex items-center justify-center gap-5">
              <li className="font-Qochy font-normal text-lg">
                <Link to="/shop">Shop</Link>
              </li>
              {/* <li className="font-Qochy  font-normal text-lg">
                <Link to="/lookbook">Lookbook</Link>
              </li> */}
              <li className="font-Qochy  font-normal text-lg">
                <Link to="/about">About</Link>
              </li>
              <li className="font-Qochy  font-normal text-lg">
                <Link to="/team">Team</Link>
              </li>
              {/* <li className="font-Optima  font-normal text-lg" onClick={handleGoogleLogin}>
                Login
              </li>
              <li className="font-Optima  font-normal text-lg" onClick={handleLogout}>
                Logout
              </li> */}
            </ul>
          </div>
          <div className="flex items-center justify-center gap-3">
            {/* <button className=" mobile:hidden sm:hidden">
              <SearchIcon />
            </button>
            <button className=" mobile:hidden sm:hidden">
              <HeartIcon />
            </button> */}
            {/* <Link to="/cart">
              <div class="flex justify-center items-center">
                <div class="relative py-2">
                  <div class="top-0 absolute left-3">
                    <p class="flex h-1 w-1 items-center justify-center rounded-full bg-red-500 p-2 text-xs text-white">
                      {cartItemCount}
                    </p>
                  </div>
                  <CartIcon />
                </div>
              </div>
            </Link> */}
            <button
              className="px-5 py-1 font-Qochy text-base font-normal bg-gray-lgray rounded-2xl"
              onClick={() => setShowForm(!showForm)}
            >
              Book <span className="mobile:hidden">an appointment </span> 
            </button>
            <div>
              <GoogleLoginButton />
            </div>
            {showForm && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-hidden">
                <div className="bg-white rounded-lg shadow-lg max-w-5xl mx-auto h-auto p-8 relative mobile:h-full mobile:w-full sm:max-w-2xl sm:h-[600px] overflow-auto">
                  <button
                    onClick={() => setShowForm(false)}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                  >
                    <CrossIcon />
                  </button>
                  <BottomSection closePopup={() => setShowForm(false)} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
