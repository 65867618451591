import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  Lines,
  TwitterIcon,
} from "../utils/Icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="w-full mt-20 mb-10 mobile:mb-6 mobile:mt-6">
      <div className="text-center font-Qochy text-xl">Manekin</div>
      <div className="flex items-center justify-center mt-5">
        <ul className="flex items-center justify-center gap-5 flex-wrap">
          <li className="font-Optima text-sm font-normal">
            <Link to="/shop">Shop</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/about">About</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/shipping">Shipping & Return policy</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/refund">Refund Policy</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/privacypolicy">Privacy Policy</Link>
          </li>
          <li className="font-Optima text-sm font-normal">
            <Link to="/contactus">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div className="flex items-center justify-center mt-5">
        <Lines />
      </div>
      <div className="mt-5">
        <ul className="flex items-center justify-center gap-3">
          <li>
            <TwitterIcon />
          </li>
          <li>
            <FacebookIcon />
          </li>
          <li>
            <InstagramIcon />
          </li>
        </ul>
      </div>
      <div className="mt-5 text-center font-Optima text-sm font-normal">
        <p>© Copyright 2024, All Rights Reserved</p>
      </div>
    </section>
  );
};

export default Footer;
