import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./context/AuthContext";
import mixpanel from "mixpanel-browser";
import reportWebVitals from "./reportWebVitals";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(MIXPANEL_TOKEN, { 
  debug: true,
  track_pageview: "full-url"
 });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals(console.log);
