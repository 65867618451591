import React, { useContext, useRef, useState } from "react";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useOutsideClick } from "../utils/useOutsideClick";
import axios from "axios";
import notify from "../utils/toastUtil";

const GoogleLoginButton = () => {
  const { setUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const redirectUrl = new URL(window.location.href).pathname;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // login handler
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/auth/google-login`,
          {
            token: tokenResponse.code,
          },
        );
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("data", JSON.stringify(data.user));
        setUser(data.user);
        navigate(redirectUrl);
      } catch (error) {
        console.error("Login failed:", error);
        notify("Failed to Login", "error");
      }
    },
    flow: "auth-code",
    onError: (error) => console.error("Login Failed:", error),
  });

  // logout handler
  const logout = () => {
    googleLogout();
    localStorage.removeItem("authToken");
    localStorage.removeItem("data");
    setUser(null);
    navigate("/");
  };

  return (
    <>
      {user ? (
        <div class="relative inline-block text-left" ref={dropdownRef}>
          <div>
            <button
              type="button"
              class="font-Qochy font-medium inline-flex w-full justify-center border gap-x-1.5 rounded-md bg-white px-3 py-2 text-base text-gray-900 shadow-sm hover:bg-gray-50"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={toggleDropdown}
            >
              {user.name}
              <svg
                class="-mr-1 size-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          {isDropdownOpen && (
            <div
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div class="py-1" role="none">
                <button
                  onClick={() => navigate("/profile")}
                  type="submit"
                  class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima"
                  role="menuitem"
                  id="menu-item-3"
                >
                  Profile
                </button>
                {user.isAdmin && (
                  <button
                    onClick={() => navigate("/orders")}
                    type="submit"
                    class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima"
                    role="menuitem"
                    id="menu-item-3"
                  >
                    Orders
                  </button>
                )}
              </div>
              <ul className="hidden mobile:block">
                <li class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima">
                  <Link to="/shop">Shop</Link>
                </li>
                <li class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima">
                  <Link to="/lookbook">Lookbook</Link>
                </li>
                <li class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima">
                  <Link to="/about">About</Link>
                </li>
              </ul>
              <div class="" role="none">
                <button
                  onClick={logout}
                  type="submit"
                  class="block w-full px-4 py-2 text-left text-sm text-gray-700 font-normal font-Optima"
                  role="menuitem"
                  id="menu-item-3"
                >
                  Sign out
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <button
          aria-label="Sign in with Google"
          class="flex items-center bg-white border border-button-border-light rounded-full p-0.5"
          onClick={() => login()}
        >
          <div class="flex items-center justify-center bg-white w-9 h-9 rounded-l">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-5 h-5"
            >
              {/* <title>Sign in with Google</title> */}
              <desc>Google G Logo</desc>
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                class="fill-google-logo-blue"
              ></path>
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                class="fill-google-logo-green"
              ></path>
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                class="fill-google-logo-yellow"
              ></path>
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                class="fill-google-logo-red"
              ></path>
            </svg>
          </div>
          <span class="text-sm text-google-text-gray tracking-wider font-Qochy">
            {/* Sign in with Google */}
          </span>
        </button>
      )}
    </>
  );
};

export default GoogleLoginButton;
