import React, { useContext, useEffect, useState } from "react";
import {
  clearCart,
  getCartItems,
  removeItemFromCart,
} from "../utils/cartUtils";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiPost } from "../utils/apiService";
import notify from "../utils/toastUtil";
import { CrossIcon } from "../utils/Icons";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  // Load cart items from localStorage
  useEffect(() => {
    setCartItems(getCartItems());
  }, []);

  // Calculate total amount
  const totalAmount = cartItems.reduce((acc, item) => acc + item.price, 0);

  const handleRemoveItem = (id) => {
    removeItemFromCart(id);
    setCartItems(getCartItems());
  };

  // Razorpay payment page
  const handlePayment = async () => {
    if (
      !shippingAddress.name ||
      !shippingAddress.address ||
      !shippingAddress.city ||
      !shippingAddress.postalCode ||
      !shippingAddress.phone
    ) {
      notify("Please fill in all the shipping details", "error");
      return;
    } else {
      setLoading(true);
      try {
        // Create order on the backend
        const amount = totalAmount * 100;
        const paymentData = {
          amount,
          products: cartItems,
          shippingAddress,
          totalPrice: amount,
          userId: user?.googleId,
        };
        const { data } = await apiPost(
          "/api/payment/create-payment-intent",
          paymentData,
        );

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: data.amount,
          currency: "INR",
          name: "Manekin",
          description: "Payment for Order",
          order_id: data.id,
          handler: function (response) {
            fetch(
              `${process.env.REACT_APP_API_URL}/api/payment/verify-payment`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  products: cartItems,
                  shippingAddress,
                  totalPrice: totalAmount,
                  userId: user?.googleId,
                }),
              },
            )
              .then((res) => res.json())
              .then((data) => {
                if (data.status === "ok") {
                  notify("Payment verified successfully", "success");
                  clearCart();
                  navigate("/order/" + data.order._id);
                } else {
                  notify("Payment verification failed", "error");
                }
              })
              .catch((error) => {
                notify("Error verifying payment", "error");
              });
          },
          prefill: {
            name: shippingAddress.name,
            email: user?.email || "example@example.com",
            contact: shippingAddress.phone,
          },
          theme: {
            color: "#F37254",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoading(false);
      } catch (error) {
        notify("Error creating payment order", "error");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress({ ...shippingAddress, [name]: value });
    localStorage.setItem("shippingAddress", JSON.stringify(shippingAddress));
  };

  useEffect(() => {
    const address = JSON.parse(localStorage.getItem("shippingAddress"));
    if (address) {
      setShippingAddress(address);
    }
  }, []);

  function handleQtyChange(e, product) {
    const newQuantity = Number(e.target.value);
    setCartItems((prevCartItems) => {
      const updatedCart = prevCartItems.map((item) => {
        if (item.product === product.product && item.size === product.size) {
          const updatedPrice =
            (Number(product.price) * newQuantity) / product.quantity;
          return {
            ...item,
            quantity: newQuantity,
            price: updatedPrice,
          };
        }
        return item;
      });

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  }

  return (
    <div className="p-4 min-h-screen">
      {cartItems.length > 0 && (
        <h1 className="text-2xl font-bold font-Qochy text-center my-4">
          Shopping Cart{" "}
          <span className="bg-[#A1A1AA] px-2 py-1 rounded-full text-base">
            {cartItems.length} items
          </span>
        </h1>
      )}

      {cartItems.length === 0 ? (
        <p className="mt-4 font-Qochy text-2xl font-bold text-center">
          Your cart is empty. Add some items to it
        </p>
      ) : (
        <div className="flex items-start justify-between gap-2 w-full mobile:flex-col">
          <div className="mt-4 w-full">
            {/* cart items */}
            <div className="w-full border bg-white rounded-lg shadow-lg px-10 mobile:px-2 sm:px-3">
              {cartItems.map((item) => (
                <>
                  <div
                    key={item.id}
                    className="flex items-start justify-between border-b py-2 relative"
                  >
                    <div className="flex items-start gap-2 sm:flex-col mobile:flex-col">
                      <img
                        src={item.image}
                        alt={item.productName}
                        className="w-32 h-32 object-cover rounded-md"
                      />
                      <div>
                        <h2 className="text-lg sm:text-base mobile:text-sm font-Qochy">
                          {item.productName}
                        </h2>
                        <p className="capitalize font-Optima text-sm font-normal">
                          {item.color}
                        </p>
                        <p className="font-Optima text-sm font-normal">
                          Size: {item.size}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 right-0">
                      <div className="flex items-center">
                        <select
                          id="qty"
                          value={item.quantity}
                          onChange={(e) => handleQtyChange(e, item)}
                          className="w-16 h-8 border rounded ml-2 text-center focus:outline-none"
                        >
                          {[...Array(10).keys()].map((num) => (
                            <option key={num + 1} value={num + 1}>
                              {num + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="font-Qochy font-medium text-lg">
                        ₹{item.price}
                      </p>
                    </div>
                    <button
                      className="absolute right-0 bottom-10"
                      onClick={() => handleRemoveItem(item.size)}
                    >
                      <CrossIcon />
                    </button>
                  </div>
                </>
              ))}
            </div>
          </div>
          {/* shipping */}
          <div className="mt-4 w-full">
            <div className="bg-white rounded-lg border shadow-lg p-4 max-w-xl mx-auto">
              <h2 className="text-xl font-bold font-Qochy">Personal Details</h2>
              <form className="mt-4 space-y-4">
                <div className="flex gap-2 w-full">
                  <div>
                    <label
                      htmlFor="firstname"
                      className="font-Qochy text-base font-normal"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      value={shippingAddress.firstname}
                      onChange={handleInputChange}
                      placeholder="First Name"
                      className="w-full p-2 border rounded font-Optima text-base font-normal"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastname"
                      className="font-Qochy text-base font-normal"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      value={shippingAddress.lastname}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      className="w-full p-2 border rounded font-Optima text-base font-normal"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="font-Qochy text-base font-normal"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={shippingAddress.email}
                    onChange={handleInputChange}
                    placeholder="Email Address"
                    className="w-full p-2 border rounded font-Optima text-base font-normal"
                    required
                  />
                </div>
                <h2 className="text-xl font-bold font-Qochy">
                  Shipping Details
                </h2>
                <div>
                  <label
                    htmlFor="address"
                    className="font-Qochy text-base font-normal"
                  >
                    Delivery address (House name/Street name/ Tower number) *
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={shippingAddress.address}
                    onChange={handleInputChange}
                    placeholder="Address"
                    className="w-full p-2 border rounded font-Optima text-base font-normal"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="font-Qochy text-base font-normal"
                  >
                    Locality/Apartment/Area *
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={shippingAddress.city}
                    onChange={handleInputChange}
                    placeholder="City"
                    className="w-full p-2 border rounded font-Optima text-base font-normal"
                    required
                  />
                </div>
                <div className="flex items-start gap-2 w-full">
                  <div>
                    <label
                      htmlFor="postalCode"
                      className="font-Qochy text-base font-normal"
                    >
                      Postal Code *
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      value={shippingAddress.postalCode}
                      onChange={handleInputChange}
                      placeholder="Postal Code"
                      className="w-full p-2 border rounded font-Optima text-base font-normal"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="font-Qochy text-base font-normal"
                    >
                      Phone Number *
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={shippingAddress.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      className="w-full p-2 border rounded font-Optima text-base font-normal"
                      required
                      maxLength={10}
                    />
                  </div>
                </div>
              </form>

              <h2 className="text-xl font-bold my-4 font-Qochy">
                Total: ₹{totalAmount}
              </h2>
              {cartItems.length > 0 && (
                <button
                  className="w-full py-4 bg-gray-lgray font-Qochy text-sm font-bold rounded-lg text-center flex items-center justify-center"
                  onClick={() => {
                    if (user) {
                      handlePayment();
                    } else {
                      notify("Please log in to proceed to payment.", "error");
                    }
                  }}
                >
                  {loading ? "Processing Payment..." : "Proceed to Payment"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
