const cartEvent = new Event("cartUpdated");

export const getCartItems = () => {
  const cart = localStorage.getItem("cart");
  return cart ? JSON.parse(cart) : [];
};

export const addItemToCart = (item) => {
  const cart = getCartItems();
  const existingItemIndex = cart.findIndex((i) => i.size === item.size);

  if (existingItemIndex !== -1) {
    // Update quantity if item already exists
    cart[existingItemIndex].quantity += item.quantity || 1;
    cart[existingItemIndex].price += item.price;
  } else {
    cart.push({ ...item, quantity: 1 });
  }

  localStorage.setItem("cart", JSON.stringify(cart));
  window.dispatchEvent(cartEvent);
};

export const removeItemFromCart = (id) => {
  // id is the size
  const cart = getCartItems();
  const updatedCart = cart.filter((item) => item.size !== id);
  localStorage.setItem("cart", JSON.stringify(updatedCart));
  window.dispatchEvent(cartEvent);
};

export const clearCart = () => {
  localStorage.removeItem("cart");
  window.dispatchEvent(cartEvent);
};
