import React from "react";
import img2 from "../assets/img2.png";
import { TitleComponent } from "../components/TitleComponent";

const TosPage = () => {
  return (
    <>
      <TitleComponent title="Terms & Conditons" description="Manekin" />
      <section className="w-full h-full px-2">
        <div
          className="flex flex-col items-center justify-center mt-3 bg-cover bg-top bg-no-repeat py-6 px-4 rounded-md"
          style={{ backgroundImage: `url(${img2})`, height: "410px" }} // Adjust height as needed
        >
          <div className="flex flex-col items-center justify-center mt-3 text-white">
            <p className="font-bold font-Qochy text-2xl mb-3">Manekin</p>
            <p className="font-Optima font-medium text-4xl">
              Terms & Conditons
            </p>
          </div>
        </div>
        {/* shipping policy  */}
        <div className="max-w-4xl mx-auto px-5">
          <div>
            <div className="flex items-center justify-center mt-5 max-w-3xl mx-auto">
              <ul className="list-decimal">
                <li className="mb-3 font-Optima font-normal text-xl">
                  For International deliveries, we mention the estimated
                  delivery charge at the time of checkout. This varies based on
                  the product, its size and volume, the packaging used and the
                  shipping destination.
                </li>
                <li className="mb-3 font-Optima font-normal text-xl">
                  For deliveries outside India, customs duties and local taxes
                  are over and above shipping charges, and have to be paid by
                  customers to our shipping partner at the time of shipment
                  delivery. The amount of duties and taxes depend on the
                  policies of your destination country.
                </li>
              </ul>
            </div>
            {/* special */}
            <div className="max-w-3xl mx-auto my-5">
              <p className="font-Optima font-normal text-xl text-center">
                Making these pieces to your specification will take us anywhere
                between 6 to 21 working days, after which they will be shipped
                out to you. Transit time within India is usually two to three
                working days.
              </p>
            </div>
            {/* contact */}
            <div className="max-w-3xl mx-auto my-5 ">
              <p className="font-Optima font-bold text-xl text-center">
                Please contact us on +91 9945670110 before placing your order,
                if you have a request for expedited delivery. We will do the
                best we can.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TosPage;
