import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import ReactPaginate from "react-paginate";
import { apiGet } from "../utils/apiService";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // Fetch orders from the database
    const fetchOrders = async (page) => {
      try {
        const data = await apiGet("/api/orders", {
          pagenumber: page,
          pagesize: 10,
        });
        setOrders(data.orders);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders(currentPage);
  }, [currentPage]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-700 text-lg">Loading orders...</div>
      </div>
    );
  }

  if (user === null && !user?.isAdmin) {
    navigate("/");
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <div className="max-w-7xl w-full mx-auto p-6 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 font-Qochy">
        Orders
      </h1>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Order id
              </th>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Customer Name
              </th>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Total Amount
              </th>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Status
              </th>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Date
              </th>
              <th className="px-6 py-3 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {orders && orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order._id} className="hover:bg-gray-100">
                  <td className="px-6 py-4 text-base font-Optima text-gray-700 border-b">
                    {order._id}
                  </td>
                  <td className="px-6 py-4 text-base font-Optima text-gray-700 border-b">
                    {order.user}
                  </td>
                  <td className="px-6 py-4 text-base font-Optima text-gray-700 border-b">
                    ₹{order.totalPrice.toFixed(2)}
                  </td>
                  <td
                    className={`px-6 py-4 text-base font-medium border-b font-Optima ${
                      order.isPaid === true
                        ? "text-green-600"
                        : "text-yellow-600"
                    }`}
                  >
                    {order.isPaid ? "Paid" : "Unpaid"}
                  </td>
                  <td className="px-6 py-4 text-base text-gray-700 border-b font-Optima">
                    {new Date(order.paidAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-base text-gray-700 border-b font-Optima">
                    <button
                      className="text-indigo-600 hover:underline font-Optima"
                      onClick={() => {
                        navigate(`/order/${order._id}`);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="px-6 py-4 text-center text-sm text-gray-500 font-Qochy"
                >
                  No orders found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="w-full px-2">
          {/* pagination */}
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< Previous"
            containerClassName="pagination"
            activeClassName="active"
            className="flex items-center justify-between py-2 px-2 font-Optima"
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
