import React from 'react';
import logo from "../assets/Manekin.png";

const Logo = () => {
    return (
        <div className="text-center w-10">
            <img src={logo} alt="Manekin" className="h-auto max-w-full" />
        </div>
    );
};

export default Logo;
