import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { apiGet } from "../utils/apiService";

const ProfilePage = () => {
  const { user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const ordersData = await apiGet("/api/orders/myorders");
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-700 text-lg font-Optima">
          Loading profile...
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-700 text-lg font-Optima ">
          User not found.
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl w-full mx-auto p-6 min-h-screen">
      <h1 className="text-2xl text-gray-800 mb-6 font-Qochy font-normal">
        Profile
      </h1>

      {/* User Details */}
      <div className="bg-white border rounded-lg p-6 mb-6">
        <h2 className="text-xl font-medium text-gray-700 mb-4 font-Qochy">
          User Details
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <p className="text-base text-gray-600 font-Qochy font-normal">
              Name
            </p>
            <p className="text-lg font-medium text-gray-800 font-Optima">
              {user.name}
            </p>
          </div>
          <div>
            <p className="text-base text-gray-600 font-Qochy font-normal">
              Email
            </p>
            <p className="text-lg font-medium text-gray-800 font-Optima">
              {user.email}
            </p>
          </div>
        </div>
      </div>

      {/* Order Details */}
      <div className="bg-white border rounded-lg p-6">
        <h2 className="text-xl font-medium text-gray-700 mb-4 font-Qochy">
          Order History
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-base font-medium text-gray-600 border-b font-Qochy">
                  Order ID
                </th>
                <th className="px-4 py-2 text-left text-base font-medium text-gray-600 border-b font-Qochy">
                  Total Amount
                </th>
                <th className="px-4 py-2 text-left text-base font-medium text-gray-600 border-b font-Qochy">
                  Status
                </th>
                <th className="px-4 py-2 text-left text-base font-medium text-gray-600 border-b font-Qochy">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-600 border-b font-Qochy">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <tr key={order._id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 text-sm text-gray-700 border-b font-Optima">
                      {order._id}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700 border-b font-Optima">
                      ₹{order.totalPrice.toFixed(2)}
                    </td>
                    <td
                      className={`px-4 py-2 text-sm font-medium border-b font-Optima ${
                        order.isPaid === true
                          ? "text-green-600"
                          : "text-yellow-600"
                      }`}
                    >
                      {order.isPaid ? "Paid" : "Unpaid"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700 border-b font-Optima">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700 border-b font-Optima">
                      <button
                        className="text-indigo-600 hover:underline"
                        onClick={() => {
                          navigate(`/order/${order._id}`);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-center text-xl text-gray-500 font-Qochy"
                  >
                    No orders found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
