import React from "react";
import img2 from "../assets/img2.png";
import { TitleComponent } from "../components/TitleComponent";

const AboutPage = () => {
  return (
    <>
      <TitleComponent title="About Us" description="Manekin" />
      <section className="w-full h-full px-2">
        <div
          className="flex flex-col items-center justify-center mt-3 bg-cover bg-top bg-no-repeat py-6 px-4 rounded-md"
          style={{ backgroundImage: `url(${img2})`, height: "410px" }} // Adjust height as needed
        >
          <div className="flex flex-col items-center justify-center mt-3 text-white">
            <p className="font-bold font-Qochy text-2xl mb-3">Manekin</p>
            <p className="font-Optima font-medium text-3xl">About the brand</p>
          </div>
        </div>
        <div className="font-normal font-Optima text-2xl max-w-5xl mx-auto my-10 text-center">
          {/* <span className="font-bold font-Qochy text-2xl">Manekin </span> */}
          Manekin is a brand made for the women entrepreneurs for
          board meetings, award shows and business parties. Our 
          garments are well-structured and give the elevated boss woman 
          look. Our collection is a marriage of Indian textiles with Western
          silhouettes, we’ve used brocades, satins and crepe in pure silk
          with golden zaris.
        </div>
        <div className="font-normal font-Optima text-2xl max-w-5xl mx-auto my-10 text-center">
          Investing in our well-tailored with luxury fabrics suits is a
          worthwhile endeavour for any female entrepreneur. By choosing
          one of our suits with embroidery, design and colour,
          you can create a wardrobe that empowers you to conquer the
          business world with style and grace.
          Elevate your Business style.
        </div>
      </section>
    </>
  );
};

export default AboutPage;
