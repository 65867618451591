import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiGet } from "../utils/apiService";

const OrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const data = await apiGet(`/api/orders/${orderId}`);
        setOrder(data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderDetails();
  }, [orderId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-700 text-lg">Loading order details...</div>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-700 text-lg">Order not found.</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl w-full mx-auto p-6 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 font-Qochy">
        Order Details
      </h1>

      <div className="bg-white border rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-700 mb-4 font-Optima text-center">
          Order ID: {orderId}
        </h2>

        <div className="grid grid-cols-2 sm:grid-cols-2 mobile:grid-cols-1 gap-4 mb-4">
          {/* left container */}
          <div>
            <div>
              <p className="text-lg text-gray-600 font-Qochy">Customer Name</p>
              <p className="text-base font-Optima font-normal text-gray-800">
                {order.user}
              </p>
            </div>
            <div>
              <p className="text-lg font-Qochy text-gray-600">Order Date</p>
              <p className="text-base font-Optima font-normal text-gray-800">
                {new Date(order.createdAt).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="text-lg text-gray-600 font-Qochy">Total Amount</p>
              <p className="text-base font-normal text-gray-800">
                ₹{order.totalPrice.toFixed(2)}
              </p>
            </div>
            <div>
              <p className="text-lg text-gray-600 font-Qochy">Status</p>
              <p
                className={`text-base font-normal font-Optima ${
                  order.isPaid === true ? "text-green-600" : "text-yellow-600"
                }`}
              >
                {order.isPaid === true ? "Paid" : "Unpaid"}
              </p>
            </div>
          </div>
          <div>
            <div>
              <p className="text-lg text-gray-600 font-Qochy">
                Shipping Address
              </p>
              <p className="text-base font-Optima font-normal text-gray-800">
                Name: {order.shippingAddress.name}
              </p>
              <p className="text-base font-Optima font-normal text-gray-800">
                Address: {order.shippingAddress.address}
              </p>
              <p className="text-base font-Optima font-normal text-gray-800">
                City: {order.shippingAddress.city}
              </p>
              <p className="text-base font-Optima font-normal text-gray-800">
                PostalCode: {order.shippingAddress.postalCode}
              </p>
              <p className="text-base font-Optima font-normal text-gray-800">
                Phone: {order.shippingAddress.phone}
              </p>
            </div>
          </div>
        </div>

        <h3 className="text-lg font-bold text-gray-700 mb-4 font-Qochy ">
          Items
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-lg font-Qochy font-medium text-gray-600 border-b ">
                  Item Name
                </th>
                <th className="px-4 py-2 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                  Color
                </th>
                <th className="px-4 py-2 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                  Size
                </th>
                <th className="px-4 py-2 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                  Quantity
                </th>
                <th className="px-4 py-2 text-left text-lg font-Qochy font-medium text-gray-600 border-b">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {order.orderItems.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="px-4 py-2 text-base font-Optima text-gray-700 border-b">
                    {item.productName}
                  </td>
                  <td className="px-4 py-2 text-base font-Optima text-gray-700 border-b">
                    {item.color}
                  </td>
                  <td className="px-4 py-2 text-base font-Optima text-gray-700 border-b">
                    {item.size}
                  </td>
                  <td className="px-4 py-2 text-base font-Optima text-gray-700 border-b">
                    {item.quantity}
                  </td>
                  <td className="px-4 py-2 text-base font-Optima text-gray-700 border-b">
                    ₹{Number(item?.price)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
