import React from "react";
import left1 from "../assets/left1.png";
import left2 from "../assets/left2.png";
import left3 from "../assets/left3.png";
import right1 from "../assets/right1.png";
import right2 from "../assets/right2.png";
import right3 from "../assets/right3.png";
import img3 from "../assets/img3.png";

const LookBookPage = () => {
  const leftContainerImages = [
    {
      img: left1,
    },
    {
      img: left2,
    },
    {
      img: left3,
    },
    {
      img: img3,
    },
  ];
  const rightcontainerImages = [
    {
      img: right1,
    },
    {
      img: right2,
    },
    {
      img: right3,
    },
    {
      img: img3,
    },
  ];
  return (
    <section>
      <div className="w-full h-full relative">
        <div
          className={`text-black flex flex-col items-center justify-center py-7`}
        >
          <p className="font-Optima font-medium text-2xl text-black mb-2">
            Masterpieces of Elegance, Crafted for You
          </p>
          <p className="font-Qochy font-normal text-5xl text-black mobile:text-2xl ">
            Sneak-Peak into Lookbooks
          </p>
        </div>
      </div>
      {/* container for images left and right */}
      <div className="grid grid-cols-2 gap-5 px-5">
        {/* left container */}
        <div>
          {leftContainerImages.map((item, index) => (
            <div className="w-full h-auto overflow-hidden relative group border">
              <img
                src={item.img}
                alt="img"
                className="w-full h-full object-cove border"
                width="100%"
                height="100%"
              />
              <div className="absolute bottom-0 left-0 bg-white w-full border px-5 py-5">
                <p className="font-Qochy font-bold text-xl my-3">
                  SPRING SUMMER 2024
                </p>
                <p className="font-Optima font-normal text-2xl my-3">
                  Mixed Materials & Shimmer Fabrics co-ords
                </p>
                <p className="underline">DISCOVER NOW</p>
              </div>
            </div>
          ))}
        </div>
        {/* rightcontainer */}
        <div className="mt-32">
          {rightcontainerImages.map((item, index) => (
            <div className="w-full h-auto overflow-hidden relative group border">
              <div className="absolute bottom-0 left-0 bg-white w-full border px-5 py-5">
                <p className="font-Qochy font-bold text-xl my-3">
                  SPRING SUMMER 2024
                </p>
                <p className="font-Optima font-normal text-2xl my-3">
                  Mixed Materials & Shimmer Fabrics co-ords
                </p>
                <p className="underline">DISCOVER NOW</p>
              </div>
              <img
                src={item.img}
                alt="img"
                className="w-full h-full object-cover border"
                width="100%"
                height="100%"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LookBookPage;
