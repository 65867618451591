import React, { useEffect, useState } from "react";
import useProducts from "../custom-hooks/useProduct";
import ProductCard from "../components/ProductCard";
import { TitleComponent } from "../components/TitleComponent";

const ShopPage = () => {
  const [products, isLoading] = useProducts();
  console.log("THE PRODUCTS ARE ", products)
  const [category, setCategory] = useState("Looks");
  const [fiilteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (category === "Looks") {
      setFilteredProducts(products.filter(
        (p) => p.fields.category?.toLowerCase() === category?.toLowerCase(),
      ));
    } else {
      setFilteredProducts(
        products.filter(
          (p) => p.fields.category?.toLowerCase() === category?.toLowerCase(),
        ),
      );
    }
  }, [products, category]);

  return (
    <>
      <TitleComponent title="Shop" description="Manekin" />
      <section className="w-full h-full p-4">
        <div className="my-5 mobile:my-2 text-center">
          {/* <p className="font-Qochy text-4xl font-normal">All Clothings</p> */}
          <div className="flex items-center justify-center w-full overflow-x-auto sm:overflow-x-scroll">
            <ul className="flex items-center gap-3 my-5 mobile:my-2 w-full">
              <li
                className={`${
                  category === "Looks" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("Looks")}>Looks</p>
              </li>
              {/* <li
                className={`${
                  category === "top" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("top")}>Top</p>
              </li> */}
              <li
                className={`${
                  category === "Corset" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("Corset")}>Corsets</p>
              </li>
              <li
                className={`${
                  category === "Blazer" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("Blazer")}>Blazers</p>
              </li>
              <li
                className={`${
                  category === "Trouser" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("Trouser")}>Trousers</p>
              </li>
             
              
              {/* <li
                className={`${
                  category === "suitset" && "underline"
                } font-Optima font-normal text-lg cursor-pointer`}
              >
                <p onClick={() => setCategory("suitset")}>Suit set</p>
              </li> */}
            </ul>
          </div>
        </div>
        <div>
          {fiilteredProducts.length === 0 && (
            <div className="text-center font-Qochy text-3xl">
              No products found..
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-2 mobile:grid-cols-1 gap-4">
          {fiilteredProducts?.map((i, index) => (
            <ProductCard data={i} key={index} />
          ))}
        </div>
      </section>
    </>
  );
};

export default ShopPage;
