import React, { useState } from "react";
import { databases } from "../appwrite/config";
import notify from "../utils/toastUtil";

const BottomSection = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [size, setSize] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        process.env.REACT_APP_APPWRITE_BOOKING_COLLECTION_ID,
        "unique()",
        {
          name,
          email,
          phone,
          message,
          size
        },
      );
      notify("Appointment booked successfully!", "success");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setSize("");
      props.closePopup()
    } catch (error) {
      notify("Failed to book appointment", "error");
    }
  };
  return (
    <div className="w-[500px] mobile:w-[350px] rounded-lg overflow-hidden flex flex-col gap-2 mobile:flex-col mt-3">
      <div className="w-full p-8 mobile:p-2 border rounded-md">
        {/* <div className="font-medium font-Qochy text-3xl min-w-72 mb-2 mobile:text-2xl">
          Let’s level up your Style
        </div> */}
       
        <h2 className="text-2xl font-Qochy font-medium mb-8 font-Optima mobile:text-2xl">
          Book an Appointment
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-Qochy"
              htmlFor="name"
            >
              Your name
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-Optima text-base"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-Qochy"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-Optima text-base"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-Qochy"
              htmlFor="phone"
            >
              Phone number
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-Optima text-base"
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-Qochy"
              htmlFor="size"
            >
              Your size (XS, S, M, L, XL, XXL)
            </label>
            <input
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-Optima text-base"
              type="text"
              id="size"
              name="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block text-base font-medium text-gray-700 font-Qochy"
              htmlFor="message"
            >
              Write your message
            </label>
            <textarea
              className="mt-1 block w-full px-4 py-2 border-b rounded-md focus:outline-none outline-none font-Optima text-base"
              id="message"
              name="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            className="w-full py-2 px-4 bg-gray-lgray font-semibold rounded-md font-Qochy"
            type="submit"
          >
            Book
          </button>
        </form>
      </div>
      <p className="font-Optima text-base font-normal mb-2 mobile:text-sm text-center">
        You can reach us anytime via hello@manekin.in
      </p>
    </div>
  );
};

export default BottomSection;
